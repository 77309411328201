export const colors = {
  primary: '#000',
  background: '#EE765B',
  lightBackground: '#FFF',
  deepPink: '#43082A',
};

export const theme = {
  ...colors,

  typography: {
    textColor: colors.deepPink,
  }
};
