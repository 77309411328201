import React from 'react';
import {
  createLocalTracks,
  LocalVideoTrack,
  LocalAudioTrack,
  createLocalVideoTrack,
  createLocalAudioTrack,
} from 'twilio-video';

import { useSelectedMediaSource } from './use-selected-media-source';

interface ContextState {
  videoTrack: LocalVideoTrack | null;
  audioTrack: LocalAudioTrack | null;
  hasPermissions: boolean;
  setVideoTrack: React.Dispatch<React.SetStateAction<null | LocalVideoTrack>>;
  setAudioTrack: React.Dispatch<React.SetStateAction<null | LocalAudioTrack>>;
  setHasPermissions: React.Dispatch<React.SetStateAction<boolean>>;
}

const LocalTracksContext = React.createContext<ContextState>({
  videoTrack: null,
  audioTrack: null,
  hasPermissions: false,
  setVideoTrack: () => null,
  setAudioTrack: () => null,
  setHasPermissions: () => null,
});

export const LocalTracksProvider: React.FC = ({ children }) => {
  const [hasPermissions, setHasPermissions] = React.useState(false);
  const [videoTrack, setVideoTrack] = React.useState<null | LocalVideoTrack>(
    null,
  );
  const [audioTrack, setAudioTrack] = React.useState<null | LocalAudioTrack>(
    null,
  );
  return (
    <LocalTracksContext.Provider
      value={{
        videoTrack,
        audioTrack,
        hasPermissions,
        setVideoTrack,
        setAudioTrack,
        setHasPermissions,
      }}
    >
      {children}
    </LocalTracksContext.Provider>
  );
};

export const useLocalTracks = () => {
  const {
    videoTrack,
    audioTrack,
    hasPermissions,
    setVideoTrack,
    setAudioTrack,
    setHasPermissions,
  } = React.useContext(LocalTracksContext);
  const { audioInputSource, videoSource } = useSelectedMediaSource();

  const connectToLocalTracks = React.useCallback(async () => {
    try {
      const tracks = await createLocalTracks({
        audio: { deviceId: audioInputSource },
        video: { deviceId: videoSource },
      });
      setHasPermissions(true);
      tracks.forEach((track) => {
        if (track.kind === 'audio') setAudioTrack(track);
        else if (track.kind === 'video') setVideoTrack(track);
      });
    } catch (err) {
      console.log(err);
      alert(
        'Oh Oh, it seems like you have not given permissions to your camera and mic',
      );
    }
  }, [audioInputSource, videoSource]);

  const connectLocalVideo = React.useCallback(async () => {
    try {
      const videoTrack = await createLocalVideoTrack({
        deviceId: videoSource,
      });
      setVideoTrack(videoTrack);
      setHasPermissions(true);
      return videoTrack;
    } catch (err) {
      console.log(err);
      alert(
        'Oh Oh, it seems like you have not given permissions to your camera',
      );
    }
  }, [videoSource]);

  const connectLocalAudio = React.useCallback(async () => {
    try {
      console.log('Connect to local audio');
      const audioTrack = await createLocalAudioTrack({
        deviceId: audioInputSource,
      });
      setAudioTrack(audioTrack);
      setHasPermissions(true);
      return audioTrack;
    } catch (err) {
      console.log(err);
      alert(
        'Oh Oh, it seems like you have not given permissions to your microphone',
      );
    }
  }, [audioInputSource]);

  React.useEffect(() => {
    if (hasPermissions) connectLocalVideo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoSource]);

  React.useEffect(() => {
    if (hasPermissions) connectLocalAudio();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioInputSource]);

  const disconnectAudio = () => {
    setAudioTrack(null);
  };

  const disconnectVideo = () => {
    setVideoTrack(null);
  };

  // Cleaners
  React.useEffect(() => {
    const localTrack = videoTrack;
    return () => {
      localTrack?.stop();
    };
  }, [videoTrack]);

  React.useEffect(() => {
    const localTrack = audioTrack;
    return () => {
      localTrack?.stop();
    };
  }, [audioTrack]);

  return {
    videoTrack,
    audioTrack,
    hasPermissions,
    connectToLocalTracks,
    connectLocalAudio,
    connectLocalVideo,
    disconnectAudio,
    disconnectVideo,
  };
};
