import * as React from 'react';
import { RemoteParticipant } from 'twilio-video';

import { useParticipant } from '../common/video/use-participant';
import { ParticipantVideo } from '../common/video/participant-video';

interface Props {
  participant?: RemoteParticipant | null;
}
export const StudentSlot: React.FC<Props> = (props) => {
  const {videoTrack, videoEnabled} = useParticipant(props.participant);
  return <ParticipantVideo videoTrack={videoTrack} videoEnabled={videoEnabled} />;
};
