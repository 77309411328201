import * as React from 'react';
import styled from 'styled-components/macro';
import { VideoTrack } from 'twilio-video';

interface Props {
  videoTrack?: VideoTrack | null;
  videoEnabled?: boolean | null;
  selected?: boolean;
  className?: string;
}

export const ParticipantVideo: React.FC<Props> = (props) => {
  const { videoTrack, videoEnabled } = props;
  const containerRef = React.useRef<null | HTMLDivElement>(null);

  React.useEffect(() => {
    if (containerRef.current && videoTrack && videoEnabled) {
      const localVideoTrack = videoTrack;
      const element = localVideoTrack.attach();
      containerRef.current.appendChild(element);

      return () => {
        console.log('Cleaning up', localVideoTrack.name, localVideoTrack.kind);
        const mediaElements = localVideoTrack.detach();
        mediaElements.forEach((mediaElement) => mediaElement.remove());
      };
    }
  }, [containerRef, videoTrack, videoEnabled]);

  return videoTrack && videoEnabled ? (
    <MediaBox ref={containerRef} selected={props.selected} className={props.className} />
  ) : null;
};

const MediaBox = styled.div<{ selected?: boolean }>`
  background-color: ${(props) => props.theme.background};
  border-radius: 4px;
  overflow: hidden;
  height: 100%;
  width: 100%;
`;
