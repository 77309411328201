import { Form } from 'formik';
import gql from 'graphql-tag';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useAccounts } from '../common/accounts';
import { Button } from '../common/components/button';
import { LoadingPage } from '../common/components/LoadingPage';
import { useQueryParams } from '../common/hooks/use-query-params';
import { LoginButton } from '../common/styled/buttons/login';
import { Title } from '../common/styled/typography/title';
import {
  useAcceptInvitationMutation,
  useGetUsedReservationQuery,
  useOneUnusedReservationQuery,
} from '../generated/graphql';
import { Invitation } from './invitation';

gql`
  mutation AcceptInvitation($reservation: ID!, $byUser: ID!) {
    acceptInvitation(reservation: $reservation, byUser: $byUser) {
      id
      availability {
        id
      }
    }
  }
`;

gql`
  query GetUsedReservation($classAvailability: ID!, $usedBy: ID!) {
    usedReservation(classAvailability: $classAvailability, usedBy: $usedBy) {
      id
    }
  }
`;

export const AuthInvitePage = () => {
  const { user } = useAccounts();
  const queryParams = useQueryParams();

  const {
    data: getInvitationResult,
    loading: getInvitationLoading,
    error: getInvitationError,
  } = useOneUnusedReservationQuery({
    variables: {
      reservationCreator: queryParams.get('user') as string,
      classAvailability: queryParams.get('classAvailability') as string,
    },
  });

  const {
    data: getUsedReservationResult,
    loading: getUsedReservationLoading,
    error: getUsedReservationError,
  } = useGetUsedReservationQuery({
    variables: {
      classAvailability: queryParams.get('classAvailability') as string,
      usedBy: user!.id,
    },
  });

  const [
    acceptInvitationByNewUserMutation,
    {
      data: acceptInvitationResult,
      loading: acceptInvitationLoading,
      error: acceptInvitationError,
    },
  ] = useAcceptInvitationMutation();

  const history = useHistory();
  React.useEffect(() => {
    if (!acceptInvitationResult) return;

    history.push(`/accept-invitation/${unusedReservation.availability.id}`);
  }, [acceptInvitationResult]);

  const [unusedReservation, setUnusedReservation] = React.useState<any>();

  React.useEffect(() => {
    setUnusedReservation(getInvitationResult?.unusedReservation);
  }, [getInvitationResult]);

  const acceptInvitation = React.useCallback(async () => {
    if (!unusedReservation || !user) return;

    acceptInvitationByNewUserMutation({
      variables: { reservation: unusedReservation.id, byUser: user.id },
    });
  }, [unusedReservation, user]);

  return (
    <div>
      {getUsedReservationLoading ? (
        <LoadingPage />
      ) : !!getUsedReservationResult &&
        getUsedReservationResult.usedReservation ? (
        <InvitationDetailsContainer>
          <Title>Invitation has been used already</Title>
          <Button onClick={() => history.push('')}>Back</Button>
        </InvitationDetailsContainer>
      ) : unusedReservation ? (
        <InvitationDetailsContainer>
          <Invitation reservation={unusedReservation} />
          <LoginButton onClick={acceptInvitation}>Accept Invite</LoginButton>
        </InvitationDetailsContainer>
      ) : !!getInvitationResult ? (
        <InvitationDetailsContainer>
          <Title>Invitation no longer valid</Title>
          <Button onClick={() => history.push('')}>Back</Button>
        </InvitationDetailsContainer>
      ) : null}
    </div>
  );
};

const Spacer = styled.div`
  flex: 1;
`;

const InvitationDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 20px 0 #00000066;
  padding: 30px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.background};
`;

const LoginContent = styled(Form)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 550px;
  padding: 40px;
`;
