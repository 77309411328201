import * as React from 'react';
import styled from 'styled-components';
import Dropdown, { Option } from 'react-dropdown';
import 'react-dropdown/style.css';
import { useHistory } from 'react-router-dom';

import { useSelectedMediaSource } from '../common/video/use-selected-media-source';
import { useMediaDevices } from '../common/video/use-media-devices';
import { useLocalTracks } from '../common/video/use-local-tracks';
import { Button } from '../common/components/button';
import { Title } from '../common/styled/typography/title';
import { AudioSink } from '../common/video/audio-sink';
import { ParticipantVideo } from '../common/video/participant-video';

export const SetupPage = () => {
  const {
    setVideoSource,
    setAudioInputSource,
    setAudioOutputSource,
    videoSource,
    audioInputSource,
    audioOutputSource,
  } = useSelectedMediaSource();

  const {
    hasPermissions,
    connectToLocalTracks,
    audioTrack,
    videoTrack,
    disconnectVideo,
    disconnectAudio,
  } = useLocalTracks();
  const {
    videoDevices,
    audioInputDevices,
    audioOutputDevices,
  } = useMediaDevices(hasPermissions);

  React.useEffect(() => {
    connectToLocalTracks();
    return () => {
      disconnectVideo();
      disconnectAudio();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onVideoSelect = React.useCallback(
    (option: Option) => setVideoSource(option.value),
    [setVideoSource],
  );
  const onAudioInputSelect = React.useCallback(
    (option: Option) => setAudioInputSource(option.value),
    [setAudioInputSource],
  );
  const onAudioOutputSelect = React.useCallback(
    (option: Option) => setAudioOutputSource(option.value),
    [setAudioOutputSource],
  );

  const { goBack } = useHistory();

  return (
    <Container>
      <Content>
        <Title>Test Your Video & Audio!</Title>
        <Row>
          <LocalPreview videoTrack={videoTrack} videoEnabled={!!videoTrack} />
          <Selectors>
            <Select
              options={videoDevices.map(deviceToOption)}
              onChange={onVideoSelect}
              value={videoSource}
            />
            <Select
              options={audioInputDevices.map(deviceToOption)}
              onChange={onAudioInputSelect}
              value={audioInputSource}
            />
            <Select
              options={audioOutputDevices.map(deviceToOption)}
              onChange={onAudioOutputSelect}
              value={audioOutputSource}
            />
            <Button onClick={goBack}>All Good!</Button>
          </Selectors>
        </Row>
      </Content>
      <AudioSink inputTrack={audioTrack} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.background};
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1000px;
  padding: 40px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
`;

const LocalPreview = styled(ParticipantVideo)`
  width: 495px;
  height: 330px;
  border-radius: 4px;
  overflow: hidden;
  transform: scale(-1, 1);
`;

const Selectors = styled.div`
  margin-left: 80px;
`;
const Select = styled(Dropdown)`
  width: 400px;
  margin-bottom: 15px;

  .Dropdown-control {
    border-radius: 4px;
  }
`;

const deviceToOption = (device: MediaDeviceInfo) => ({
  label: device.label,
  value: device.deviceId,
});
