import React from 'react';

export const useMediaDevices = (hasPermissions: boolean) => {
  const [videoDevices, setVideoDevices] = React.useState<MediaDeviceInfo[]>([]);
  const [audioInputDevices, setAudioInputDevices] = React.useState<MediaDeviceInfo[]>([]);
  const [audioOutputDevices, setAudioOutputDevices] = React.useState<MediaDeviceInfo[]>([]);

  React.useEffect(() => {
    if (hasPermissions) {
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        const ai: MediaDeviceInfo[] = [];
        const ao: MediaDeviceInfo[] = [];
        const v: MediaDeviceInfo[] = [];
        devices.forEach((device) => {
          if (device.kind === 'videoinput') {
            v.push(device);
          } else if (device.kind === 'audioinput') {
            ai.push(device);
          } else if (device.kind === 'audiooutput') {
            ao.push(device);
          }
        });
        setVideoDevices(v);
        setAudioInputDevices(ai);
        setAudioOutputDevices(ao);
      });
    }
  }, [hasPermissions]);

  return {
    videoDevices,
    audioInputDevices,
    audioOutputDevices,
  };
};
