import * as React from 'react';
import { RemoteParticipant } from 'twilio-video';

import { useGetClassAvailabilityQuery, Role } from '../generated/graphql';
import { useAccounts } from '../common/accounts';
import gql from 'graphql-tag';

gql`
  query GetClassAvailability($availabilityId: ID!) {
    classAvailability(id: $availabilityId) {
      id
      startDateTime
      endDateTime
      class {
        id
        name
        teacher {
          id
          firstName
          lastName
        }
      }
      mainRoom {
        id
      }
    }
  }
`;

export const useClassroom = (
  availabilityId: string,
  participants: RemoteParticipant[],
) => {
  const { data } = useGetClassAvailabilityQuery({
    variables: { availabilityId },
  });
  const { user } = useAccounts();

  const classTeacher = data?.classAvailability?.class?.teacher;
  const amITeacher =
    user?.role === Role.Teacher && user?.id === classTeacher?.id;

  const remoteTeacher = React.useMemo(
    () =>
      participants.find(
        (participant) => participant?.identity === classTeacher?.id,
      ),
    [participants],
  );

  const numberOfStudents = amITeacher
    ? participants.length
    : remoteTeacher
    ? participants.length
    : participants.length + 1;

  return {
    className: data?.classAvailability?.class.name,
    amITeacher,
    remoteTeacher,
    numberOfStudents,
  };
};
