import * as React from 'react';
import {
  RemoteParticipant,
  RemoteTrack,
  RemoteTrackPublication,
  RemoteVideoTrack,
  RemoteAudioTrack,
} from 'twilio-video';

import { useRemoteAudio } from './use-remote-audio';

export const useParticipant = (participant?: RemoteParticipant | null) => {
  const [videoTrack, setVideoTrack] = React.useState<null | RemoteVideoTrack>(
    null,
  );
  const [audioTrack, setAudioTrack] = React.useState<null | RemoteAudioTrack>(
    null,
  );
  const [
    screenCastTrack,
    setScreenCastTrack,
  ] = React.useState<null | RemoteVideoTrack>(null);
  const [videoEnabled, setVideoEnabled] = React.useState(true);
  const [audioEnabled, setAudioEnabled] = React.useState(true);
  const [screenCastEnabled, setScreenCastEnabled] = React.useState(false);

  const { setAudioTrackMap } = useRemoteAudio();

  React.useEffect(() => {
    if (participant) {
      const localParticipant = participant;

      const handleNewTrack = (track: RemoteTrack) => {
        if (track.kind === 'audio') {
          setAudioTrack(track);
          setAudioTrackMap((audioTrackMap) => ({
            ...audioTrackMap,
            [localParticipant.sid]: track,
          }));
        } else if (track.kind === 'video') {
          if (track.name.startsWith('screen-share')) setScreenCastTrack(track);
          else setVideoTrack(track);
        }
      };

      localParticipant.tracks?.forEach(
        (publication: RemoteTrackPublication) => {
          if (publication.track) {
            const track = publication.track;
            handleNewTrack(track);
          }
        },
      );
      localParticipant.on('trackSubscribed', handleNewTrack);

      // Clear tracks on unsubscribe
      localParticipant.on('trackUnsubscribed', (track: RemoteTrack) => {
        if (track.kind === 'audio') {
          setAudioTrack(null);
          setAudioTrackMap((audioTrackMap) => {
            delete audioTrackMap[localParticipant.sid];
            return audioTrackMap;
          });
        } else if (track.kind === 'video') {
          if (track.name.startsWith('screen-share')) setScreenCastTrack(null);
          else setVideoTrack(null);
        }
      });

      return () => {
        localParticipant.removeAllListeners();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participant]);

  React.useEffect(() => {
    const localVideoTrack = videoTrack;
    const localAudioTrack = audioTrack;
    const localScreenTrack = screenCastTrack;
    if (localVideoTrack) {
      localVideoTrack.on('enabled', () => setVideoEnabled(true));
      localVideoTrack.on('disabled', () => setVideoEnabled(false));
    }
    if (localAudioTrack) {
      localAudioTrack.on('enabled', () => setAudioEnabled(true));
      localAudioTrack.on('disabled', () => setAudioEnabled(false));
    }
    if (localScreenTrack) {
      localScreenTrack.on('enabled', () => setScreenCastEnabled(true));
      localScreenTrack.on('disabled', () => setScreenCastEnabled(false));
    }
    return () => {
      localVideoTrack?.removeAllListeners();
      localAudioTrack?.removeAllListeners();
      localScreenTrack?.removeAllListeners();
    };
  }, [videoTrack, audioTrack, screenCastTrack]);

  return {
    videoTrack,
    videoEnabled,
    audioEnabled,
    screenCastTrack,
    screenCastEnabled,
  };
};
