import styled from 'styled-components';
import { Field } from 'formik';

export const LoginInput = styled(Field)`
  border: none;
  padding: 12px;
  box-sizing: border-box;
  margin-bottom: 5px;
  width: 100%;
  font-family: Poppins;
  border-radius: 4px;
`;
