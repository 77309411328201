import styled from 'styled-components';

export const LoginButton = styled.button`
  border: none;
  text-transform: uppercase;
  padding: 12px;
  width: 100%;
  margin: 0;
  font-weight: 800;
  border-radius: 4px;
  background-color: ${(props) => props.theme.deepPink};
  color: ${(props) => props.theme.lightBackground};
  user-select: none;
  margin-top: 30px;
`;
