import * as React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '../common/components/button';
import { Title } from '../common/styled/typography/title';
import moment from 'moment';

interface Props {
  startTime?: string;
}

export const PreClass: React.FC<Props> = ({ startTime }) => {
  const history = useHistory();

  const [countDown, setCountdown] = React.useState<string | undefined>();

  React.useEffect(() => {
    if (!startTime) return;

    setCountdown(moment(startTime).fromNow());
    const intervalId = setInterval(() => {
      setCountdown(moment(startTime).fromNow());
    }, 60000);

    return () => clearInterval(intervalId);
  }, [startTime]);
  return (
    <InvitationDetailsContainer>
      <Title>Class starting in:</Title>
      <div>{countDown}</div>
      <Button onClick={() => history.push('')}>Back</Button>
    </InvitationDetailsContainer>
  );
};

const InvitationDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 20px 0 #00000066;
  padding: 30px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.background};
`;
