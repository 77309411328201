import * as React from 'react';
import gql from 'graphql-tag';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { useGetAvailableClassesQuery } from '../generated/graphql';
import { Title } from '../common/styled/typography/title';
import { Subtitle } from '../common/styled/typography/subtitle';
import { Text } from '../common/styled/typography/text';
import { useAccounts } from '../common/accounts';

gql`
  query GetAvailableClasses($skip: Int, $limit: Int) {
    availableClasses(skip: $skip, limit: $limit) {
      id
      name
      teacher {
        id
        firstName
        lastName
      }
      availabilities {
        id
        mainRoom {
          id
        }
        startDateTime
        endDateTime
      }
      slots
      durationHours
    }
  }
`;

export const ClassListPage = () => {
  const { data } = useGetAvailableClassesQuery({ variables: { skip: 0, limit: 20 } });
  const { logout } = useAccounts();

  return (
    <Container>
      <Content>
        <PageTitle>Classes</PageTitle>
        {data?.availableClasses?.map((availableClass) => (
          <ClassCard key={`class-${availableClass.id}`}>
            <Title>{availableClass.name}</Title>
            <Text>By</Text>
            <Subtitle>
              {availableClass.teacher?.firstName} {availableClass.teacher?.lastName}
            </Subtitle>
            <Text>Available slots {availableClass.slots}</Text>
            <Text>Duration {availableClass.durationHours} hours</Text>
            <Subtitle>Availabilities</Subtitle>
            {availableClass.availabilities?.map((availability) => (
              <Link key={`availability=${availability.id}`} to={`/classroom/${availability.id}`}>
                Starting at {new Date(availability.startDateTime).toLocaleString()}
              </Link>
            ))}
          </ClassCard>
        ))}
        <Spacer />
        <Footer>
          <Link to={'/setup'}>Setup Video</Link>
          <LogoutLink onClick={logout}>Logout</LogoutLink>
        </Footer>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.background};
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  padding: 40px;
`;

const ClassCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 20px 0 #00000066;
  padding: 30px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.lightBackground};
`;

const PageTitle = styled(Title)`
  margin-bottom: 100px;
`;

const Spacer = styled.div`
  flex: 1;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const LogoutLink = styled(Text)`
  text-decoration: underline;
  cursor: pointer;
`;