import * as React from 'react';

import { useLocalStorage } from '../hooks/use-local-storage';

const LS_VIDEO_SOURCE_KEY = 'school::videoSourceId';
const LS_AUDIO_INPUT_SOURCE_KEY = 'school::audioInputSourceId';
const LS_AUDIO_OUTPUT_SOURCE_KEY = 'school::audioOutputSourceId';

const MediaSourceContext = React.createContext({
  videoSource: '',
  audioInputSource: '',
  audioOutputSource: '',
  setVideoSource: (value: string) => {},
  setAudioInputSource: (value: string) => {},
  setAudioOutputSource: (value: string) => {},
});

export const MediaSourceProvider: React.FC = (props) => {
  const [videoSource, setVideoSource] = useLocalStorage(LS_VIDEO_SOURCE_KEY, '');
  const [audioInputSource, setAudioInputSource] = useLocalStorage(LS_AUDIO_INPUT_SOURCE_KEY, '');
  const [audioOutputSource, setAudioOutputSource] = useLocalStorage(LS_AUDIO_OUTPUT_SOURCE_KEY, '');

  return (
    <MediaSourceContext.Provider
      value={{
        videoSource,
        audioInputSource,
        audioOutputSource,
        setVideoSource,
        setAudioInputSource,
        setAudioOutputSource,
      }}
    >
      {props.children}
    </MediaSourceContext.Provider>
  );
};

export const useSelectedMediaSource = () => React.useContext(MediaSourceContext);
