import { Formik } from 'formik';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useAccounts } from '../common/accounts';
import { LoginButton } from '../common/styled/buttons/login';
import { LoginContainer } from '../common/styled/containers/login/container';
import { LoginContent } from '../common/styled/input/login/form';
import { LoginInput } from '../common/styled/input/login/input';
import { Title } from '../common/styled/typography/title';

interface Values {
  email: string;
  password: string;
}

interface Props {
  loggedInPath: string;
}

export const LoginPage = ({ loggedInPath }: Props) => {
  const { login } = useAccounts();

  const history = useHistory();
  const location = useLocation();

  const onSubmit = React.useCallback(
    async (values: Values) => {
      await login(values.email, values.password);
      //@ts-ignore
      if (location.state?.from) {
        history.replace({
          //@ts-ignore
          pathname: location.state?.from,
          //@ts-ignore
          search: location.state?.search,
        });
      } else {
        history.replace(loggedInPath);
      }
    },
    [login],
  );
  return (
    <Formik initialValues={{ email: '', password: '' }} onSubmit={onSubmit}>
      {() => (
        <LoginContainer>
          <LoginContent>
            <Title>Please Login to Skhool!</Title>
            <LoginInput name="email" placeholder="Enter Email" />
            <LoginInput name="password" placeholder="Enter Password" />
            <LoginButton type="submit">Login</LoginButton>
            <Spacer />
          </LoginContent>
        </LoginContainer>
      )}
    </Formik>
  );
};

const Spacer = styled.div`
  flex: 1;
`;
