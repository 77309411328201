import * as React from 'react';
import {
  LocalAudioTrackPublication,
  LocalParticipant,
  LocalVideoTrackPublication,
} from 'twilio-video';
import { useLocalTracks } from './use-local-tracks';

interface ContextState {
  micEnabled: boolean;
  camEnabled: boolean;
  setMicEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  setCamEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const MuteContext = React.createContext<ContextState>({
  micEnabled: true,
  setMicEnabled: () => null,
  camEnabled: true,
  setCamEnabled: () => null,
});

export const MuteContextProvider: React.FC = ({ children }) => {
  const [micEnabled, setMicEnabled] = React.useState(true);
  const [camEnabled, setCamEnabled] = React.useState(true);

  return (
    <MuteContext.Provider
      value={{
        micEnabled,
        setMicEnabled,
        camEnabled,
        setCamEnabled,
      }}
    >
      {children}
    </MuteContext.Provider>
  );
};

export const useMute = (localParticipant?: LocalParticipant) => {
  const {
    camEnabled,
    setCamEnabled,
    micEnabled,
    setMicEnabled,
  } = React.useContext(MuteContext);
  const { connectLocalVideo, disconnectVideo } = useLocalTracks();

  const mute = React.useCallback(() => {
    localParticipant?.audioTracks.forEach(
      (publication: LocalAudioTrackPublication) => {
        console.log('Mute', publication.track);
        publication.track.disable();
      },
    );
    setMicEnabled(false);
  }, [localParticipant]);
  const unmute = React.useCallback(() => {
    localParticipant?.audioTracks.forEach(
      (publication: LocalAudioTrackPublication) => {
        console.log('Unmute', publication.track);
        publication.track.enable();
      },
    );
    setMicEnabled(true);
  }, [localParticipant]);

  const turnOffCam = React.useCallback(() => {
    localParticipant?.videoTracks.forEach(
      (publication: LocalVideoTrackPublication) => {
        disconnectVideo();
        publication.track.stop();
        publication.unpublish();
      },
    );
    setCamEnabled(false);
  }, [localParticipant]);

  const turnOnCam = React.useCallback(async () => {
    const track = await connectLocalVideo();
    if (track) {
      localParticipant?.publishTrack(track);
      setCamEnabled(true);
    }
  }, [localParticipant]);

  return {
    mute,
    unmute,
    turnOnCam,
    turnOffCam,
    camEnabled,
    micEnabled,
  };
};
