import * as React from 'react';
import { RemoteAudioTrack } from 'twilio-video';

type AudioTrackMap = { [key: string]: RemoteAudioTrack };
interface RemoteAudioMapState {
  audioTrackMap: AudioTrackMap;
  setAudioTrackMap: React.Dispatch<React.SetStateAction<AudioTrackMap>>;
}
const RemoteAudioMapContext = React.createContext<RemoteAudioMapState>({
  audioTrackMap: {},
  setAudioTrackMap: () => null,
});

export const RemoteAudioMapProvider: React.FC = (props) => {
  const [audioTrackMap, setAudioTrackMap] = React.useState({});
  return (
    <RemoteAudioMapContext.Provider value={{ audioTrackMap, setAudioTrackMap }}>
      {props.children}
    </RemoteAudioMapContext.Provider>
  );
};

export const useRemoteAudio = () => React.useContext(RemoteAudioMapContext);
