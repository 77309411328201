import React from 'react';
import { ClimbingBoxLoader } from 'react-spinners';
import styled from 'styled-components';

export const LoadingPage = () => {
  return (
    <Container>
      <Content>
        <ClimbingBoxLoader size={30} color="#FFF" />
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.background};
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 800px;
  padding: 40px;
`;
