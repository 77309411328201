import * as React from 'react';
import styled from 'styled-components';

import { ReactComponent as MicOffIcon } from '../assets/img/icons/mic/off.svg';
import { ReactComponent as MicOnIcon } from '../assets/img/icons/mic/on.svg';
import { ReactComponent as CamOffIcon } from '../assets/img/icons/cam/off.svg';
import { ReactComponent as CamOnIcon } from '../assets/img/icons/cam/on.svg';
import { ReactComponent as HangupIcon } from '../assets/img/icons/leave.svg';
import { useMute } from '../common/video/use-mute';
import { LocalParticipant } from 'twilio-video';

interface Props {
  onShareScreen?: () => void;
  localParticipant?: LocalParticipant;
}
export const VideoControls: React.FC<Props> = ({
  onShareScreen,
  localParticipant,
}) => {
  const {
    mute,
    unmute,
    turnOffCam,
    turnOnCam,
    camEnabled,
    micEnabled,
  } = useMute(localParticipant);
  return (
    <Controls>
      <ControlButton onClick={micEnabled ? mute : unmute}>
        {micEnabled ? (
          <MicOnIcon fill="white" width={23} />
        ) : (
          <MicOffIcon fill="white" width={23} />
        )}
      </ControlButton>
      <ControlButton onClick={camEnabled ? turnOffCam : turnOnCam}>
        {camEnabled ? (
          <CamOnIcon fill="white" width={23} />
        ) : (
          <CamOffIcon fill="white" width={23} />
        )}
      </ControlButton>
      <ControlButton>
        <HangupIcon fill="white" width={23} />
      </ControlButton>
      {onShareScreen ? (
        <ShareScreenButton onClick={onShareScreen}>
          Share Screen
        </ShareScreenButton>
      ) : null}
    </Controls>
  );
};

const Controls = styled.div`
  display: flex;
  margin-top: 50px;
  align-items: center;
`;

const ControlButton = styled.button`
  border: none;
  margin: 0;
  user-select: none;
  border-radius: 4px;
  background-color: ${(props) => props.theme.deepPink};
  color: ${(props) => props.theme.lightBackground};
  height: 40px;
  margin-right: 10px;
`;

const ShareScreenButton = styled(ControlButton)`
  width: unset;
`;
