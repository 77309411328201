import * as React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import styled from 'styled-components';

import { Title } from '../common/styled/typography/title';
import { useAccounts } from '../common/accounts';
import { useParams, useHistory } from 'react-router-dom';
import { LoginContainer } from '../common/styled/containers/login/container';
import { LoginContent } from '../common/styled/input/login/form';
import { LoginInput } from '../common/styled/input/login/input';
import { LoginButton } from '../common/styled/buttons/login';

interface Values {
  password: string;
  passwordRepeat: string;
}

export const SetPasswordPage = () => {
  const { resetPassword } = useAccounts();
  const { token } = useParams();
  const history = useHistory();
  const [error, setError] = React.useState<string | undefined>();

  const onSubmit = React.useCallback(async (values: Values) => {
    resetPassword(
      token,
      values.password,
      () => history.push('/login'),
      (e) => setError(e.message)
    );
  }, []);

  return (
    <Formik
      initialValues={{ password: '', passwordRepeat: '' }}
      onSubmit={onSubmit}
      validate={({ password, passwordRepeat }) => {
        return password !== passwordRepeat
          ? { passwordRepeat: 'Passwords have to be equal' }
          : {};
      }}
    >
      {() => (
        <LoginContainer>
          <LoginContent>
            <Title>Let's Set Your Skhool Password:</Title>
            <LoginInput name="password" placeholder="Enter Password" />
            <LoginInput name="passwordRepeat" placeholder="Repeat Password" />
            <ErrorMessage name="passwordRepeat" />
            {error ? <div>{error}</div> : null}
            <LoginButton type="submit">Set Password</LoginButton>
            <Spacer />
          </LoginContent>
        </LoginContainer>
      )}
    </Formik>
  );
};

const Spacer = styled.div`
  flex: 1;
`;
