import moment from 'moment';
import * as React from 'react';
import styled from 'styled-components';
import { Subtitle } from '../common/styled/typography/subtitle';
import { Title } from '../common/styled/typography/title';
import { Reservation } from '../generated/graphql';

type InvitationProps = {
  reservation: Reservation;
};

export const Invitation = ({ reservation }: InvitationProps) => (
  <InvitationDetailsContainer>
    <Title>
      You've Been Invited By
      {
        <span>
          {' '}
          {reservation.reservingUser.firstName +
            ' ' +
            reservation.reservingUser.lastName}
        </span>
      }
    </Title>
    <Subtitle>to {reservation.availability.class.name} class</Subtitle>
    <Subtitle>
      that starts on{' '}
      {moment(reservation.availability.startDateTime).format('LLLL')}
    </Subtitle>
    <Subtitle>
      and ends on {moment(reservation.availability.endDateTime).format('LLLL')}
    </Subtitle>
  </InvitationDetailsContainer>
);

const InvitationDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 20px 0 #00000066;
  padding: 30px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.background};
`;
