import gql from 'graphql-tag';
import moment from 'moment';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { CLASS_END_DATE_FORMAT } from '../common/constants';
import {
  useGetClassAvailabilityWithMyReservationQuery,
  Role,
} from '../generated/graphql';
import { Classroom } from './classroom';
import { InAccessibleClass } from './inaccessible-class';
import { PostClass } from './post-class';
import { PreClass } from './pre-class';
import { useAccounts } from '../common/accounts';

gql`
  query GetClassAvailabilityWithMyReservation($availabilityId: ID!) {
    classAvailability(id: $availabilityId) {
      id
      startDateTime
      endDateTime
      class {
        id
        name
        teacher {
          id
          firstName
          lastName
        }
      }
      mainRoom {
        id
      }
      myReservation {
        id
      }
    }
  }
`;

interface Props {}

enum ClassState {
  NotStarted,
  InAction,
  Finished,
  NotAccessible,
}

export const ClassContainer: React.FC<Props> = () => {
  const { user } = useAccounts();
  const { availabilityId } = useParams();
  const { data } = useGetClassAvailabilityWithMyReservationQuery({
    variables: { availabilityId },
  });

  const [classState, setClassState] = React.useState<ClassState | undefined>();

  const className = data?.classAvailability?.class?.name;

  React.useEffect(() => {
    if (!data) return;

    if (user?.role === Role.Student && !data.classAvailability?.myReservation) {
      setClassState(ClassState.NotAccessible);
    } else if (
      moment(data?.classAvailability?.startDateTime).isAfter(moment.now())
    ) {
      setClassState(ClassState.NotStarted);
    } else if (
      moment(data?.classAvailability?.endDateTime).isBefore(moment.now())
    ) {
      setClassState(ClassState.Finished);
    } else {
      setClassState(ClassState.InAction);
    }
  }, [data]);

  return classState === ClassState.NotAccessible ? (
    <InAccessibleClass />
  ) : classState === ClassState.NotStarted ? (
    <PreClass startTime={data?.classAvailability?.startDateTime} />
  ) : classState === ClassState.InAction ? (
    <Classroom availabilityId={availabilityId} />
  ) : classState === ClassState.Finished ? (
    <PostClass
      className={className}
      endTime={moment(data?.classAvailability?.endDateTime).format(
        CLASS_END_DATE_FORMAT,
      )}
    />
  ) : null;
};

const Container = styled.div`
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
