import * as React from 'react';
import styled from 'styled-components';
import { Title } from '../common/styled/typography/title';
import { AudioSink } from '../common/video/audio-sink';
import { ParticipantVideo } from '../common/video/participant-video';
import { useLocalTracks } from '../common/video/use-local-tracks';
import { useParticipant } from '../common/video/use-participant';
import { RemoteAudioMapProvider } from '../common/video/use-remote-audio';
import { useRoom } from '../common/video/use-room';
import { useScreenTrack } from '../common/video/use-screen-track';
import { MaterialsBoard } from './materials-board';
import { StudentSlot } from './student-slot';
import { useClassroom } from './use-classroom';
import { VideoControls } from './video-controls';

interface Props {
  availabilityId: string;
}

export const Classroom: React.FC<Props> = ({ availabilityId }) => {
  const {
    videoTrack,
    audioTrack,
    connectLocalVideo,
    connectLocalAudio,
    disconnectAudio,
    disconnectVideo,
  } = useLocalTracks();

  React.useEffect(() => {
    connectLocalVideo();
    connectLocalAudio();
    return () => {
      disconnectVideo();
      disconnectAudio();
    };
  }, []);

  const { room, participants } = useRoom(
    { id: availabilityId, name: 'classroom' },
    'standard',
    videoTrack,
    audioTrack,
  );

  const {
    amITeacher,
    numberOfStudents,
    remoteTeacher,
    className,
  } = useClassroom(availabilityId, participants);

  const {
    videoTrack: teacherVideo,
    videoEnabled: teacherVideoEnabled,
    screenCastTrack,
  } = useParticipant(remoteTeacher);

  const { screenTrack, createScreenTrack } = useScreenTrack();
  const shareScreen = React.useCallback(() => {
    createScreenTrack(room);
  }, [createScreenTrack, room]);

  const teacherScreenTrack = amITeacher ? screenTrack : screenCastTrack;
  const teacherVideoTrack = amITeacher ? videoTrack : teacherVideo;

  return (
    <RemoteAudioMapProvider>
      <Container>
        <Title>{className}</Title>
        <TeacherStage>
          <TeacherSlot>
            <ParticipantVideo
              videoTrack={teacherVideoTrack}
              videoEnabled={teacherVideoEnabled}
            />
          </TeacherSlot>
          {teacherScreenTrack && (
            <TeacherSlot>
              <MaterialsBoard videoTrack={teacherScreenTrack} />
            </TeacherSlot>
          )}
        </TeacherStage>
        <Grid numberOfParticipants={numberOfStudents}>
          {!amITeacher ? (
            <ParticipantVideo
              videoTrack={videoTrack}
              videoEnabled={!!videoTrack}
            />
          ) : null}
          {participants?.map((participant) => {
            return participant !== remoteTeacher ? (
              <StudentSlot
                key={`Participant-${participant?.sid}`}
                participant={participant}
              />
            ) : null;
          })}
        </Grid>
        <VideoControls
          onShareScreen={amITeacher ? shareScreen : undefined}
          localParticipant={room?.localParticipant}
        />
        <AudioSink inputTrack={audioTrack} />
      </Container>
    </RemoteAudioMapProvider>
  );
};

const Container = styled.div`
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Grid = styled.div<{ numberOfParticipants: number }>`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(
    ${(props) => props.numberOfParticipants},
    ${(props) => (props.numberOfParticipants > 6 ? '64px' : '128px')}
  );
  grid-template-rows: ${(props) =>
    props.numberOfParticipants > 6 ? '43px' : '86px'};
  grid-gap: 32px;
  justify-items: center;
  justify-content: center;
`;

const TeacherStage = styled.div`
  width: 80vw;
  height: 45vh;
  min-height: 330px;
  display: flex;
  justify-content: center;
`;

const TeacherSlot = styled.div`
  flex: 1;
  :last-child {
    margin-left: 30px;
  }
  border-radius: 4px;
  overflow: hidden;
`;
