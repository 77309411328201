import * as React from 'react';
import { LocalVideoTrack, Room, LocalTrackPublication } from 'twilio-video';

interface ExtendedMediaDevices extends MediaDevices {
  getDisplayMedia?(): Promise<MediaStream>;
}

const mediaDevicesApi = (navigator.mediaDevices as unknown) as ExtendedMediaDevices;

export const useScreenTrack = () => {
  const [screenTrack, setScreenTrack] = React.useState<null | LocalVideoTrack>(
    null,
  );
  const [publication, setPublication] = React.useState<
    null | LocalTrackPublication | undefined
  >(null);

  const createScreenTrack = React.useCallback(
    async (room?: Room | null) => {
      if (mediaDevicesApi.getDisplayMedia) {
        if (screenTrack) {
          screenTrack.stop();
          publication?.unpublish();
        }
        const stream = await mediaDevicesApi.getDisplayMedia();
        const currentScreenTrack = stream.getTracks()[0];
        const track = new LocalVideoTrack(currentScreenTrack, {
          name: 'screen-share-' + createScreenTrack.name,
          logLevel: 'off',
        });
        setPublication(await room?.localParticipant.publishTrack(track));
        setScreenTrack(track);
      } else {
        throw new Error('Browser does not support screen capture!');
      }
    },
    [screenTrack, publication],
  );

  React.useEffect(() => {
    if (screenTrack && publication) {
      const localPublication = publication;
      const localScreenTrack = screenTrack;

      localScreenTrack.on('stopped', () => {
        localPublication?.unpublish();
        setScreenTrack(null);
      });

      return () => {
        localPublication?.unpublish();
        localScreenTrack.removeAllListeners();
      };
    }
  }, [screenTrack, publication]);

  return {
    createScreenTrack,
    screenTrack,
  };
};
