import React, { ReactNode } from 'react';
import { LoadingPage } from './components/LoadingPage';
import { Redirect, Route } from 'react-router-dom';
import { useAccounts } from './accounts';

type Props = {
  path: string;
  children: ReactNode;
};
export const ProtectedRoute = ({ path, children, ...rest }: Props) => {
  const { isLoggedIn, loggingIn } = useAccounts();

  return (
    <Route path={path}>
      {loggingIn ? (
        <LoadingPage />
      ) : isLoggedIn ? (
        children
      ) : (
        <Redirect to={'/login'} />
      )}
    </Route>
  );
};

// {isLoggedIn ? <children {...rest} /> : <Redirect to={'/login'} />}
