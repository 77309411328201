import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '../common/components/button';
import { Title } from '../common/styled/typography/title';

export const InvitationAcceptedPage = () => {
  const history = useHistory();
  const { availabilityId } = useParams();

  const navigateToMainPage = React.useCallback(() => {
    if (!availabilityId) return;

    history.push(`/classroom/${availabilityId}`);
  }, [history, availabilityId]);

  return (
    <InvitationDetailsContainer>
      <Title>Invitation Accepted Successfully</Title>
      <Button onClick={navigateToMainPage}>Continue</Button>
    </InvitationDetailsContainer>
  );
};

const InvitationDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 20px 0 #00000066;
  padding: 30px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.background};
`;
