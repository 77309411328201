import * as React from 'react';
import { LocalAudioTrack, RemoteAudioTrack } from 'twilio-video';
import styled from 'styled-components';
import { useRemoteAudio } from './use-remote-audio';
import { useSelectedMediaSource } from './use-selected-media-source';

interface Props {
  inputTrack?: LocalAudioTrack | null;
}

export const AudioSink: React.FC<Props> = ({ inputTrack }) => {
  const { audioTrackMap } = useRemoteAudio();
  const outputTracks = React.useMemo(() => Object.entries(audioTrackMap), [
    audioTrackMap,
  ]);
  const { audioOutputSource } = useSelectedMediaSource();

  return (
    <InvisibleDom>
      <ParticipantAudio track={inputTrack} />
      {outputTracks?.map(([sid, outputTrack]) => (
        <ParticipantAudio
          key={`audio-track-${sid}`}
          track={outputTrack}
          sinkId={audioOutputSource}
        />
      ))}
    </InvisibleDom>
  );
};

interface ParticipantAudioProps {
  track?: LocalAudioTrack | RemoteAudioTrack | null;
  sinkId?: string;
}

const ParticipantAudio: React.FC<ParticipantAudioProps> = ({
  track,
  sinkId,
}) => {
  const containerRef = React.useRef<null | HTMLDivElement>(null);
  const [
    audioElement,
    setAudioElement,
  ] = React.useState<null | ExtendedAudioElement>(null);
  React.useEffect(() => {
    if (containerRef.current && track) {
      const localTrack = track;
      console.log('Attaching track', localTrack.name);
      const audioElm = (localTrack.attach() as unknown) as ExtendedAudioElement;
      setAudioElement(audioElm);
      containerRef.current.appendChild(audioElm);
      return () => {
        console.log('Cleaning audio', localTrack.name);
        const audioElements = localTrack.detach();
        audioElements.forEach((ae) => ae.remove());
        setAudioElement(null);
      };
    }
  }, [containerRef, track]);

  // Set audio output device
  React.useEffect(() => {
    if (sinkId && audioElement) {
      audioElement.setSinkId?.(sinkId).catch((e) => {
        console.log('Error with playing audio on', sinkId, e);
      });
    }
  }, [audioElement, sinkId]);

  return <InvisibleDom ref={containerRef} />;
};

const InvisibleDom = styled.div`
  display: none;
`;

interface ExtendedAudioElement extends HTMLAudioElement {
  setSinkId?(sinkId: string): Promise<undefined>;
}
