import * as React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '../common/components/button';
import { Title } from '../common/styled/typography/title';

interface Props {
  className?: string;
  endTime: string;
}

export const PostClass: React.FC<Props> = ({ className, endTime }) => {
  const history = useHistory();

  return (
    <InvitationDetailsContainer>
      <Title>{`${className} class has ended in ${endTime}`}</Title>
      <Button onClick={() => history.push('')}>Back</Button>
    </InvitationDetailsContainer>
  );
};

const InvitationDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 20px 0 #00000066;
  padding: 30px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.background};
`;
