import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { LoginPage } from './auth/login-page';
import { SetPasswordPage } from './auth/set-password-page';
import { ClassListPage } from './catalouge/classes-list-page';
import { ClassContainer } from './classroom/class-container';
import { ProtectedRoute } from './common/ProtectedRoute';
import { InvitationAcceptedPage } from './invitation/invitation-accepted-page';
import { InvitePage } from './invitation/invite-page';
import { SetupPage } from './setup/setup-page';

export default function Router() {
  return (
    <BrowserRouter>
      <InnerRoutes />
    </BrowserRouter>
  );
}

const InnerRoutes = () => {
  return (
    <Switch>
      <ProtectedRoute path="/classroom/:availabilityId">
        <ClassContainer />
      </ProtectedRoute>
      <ProtectedRoute path="/classes">
        <ClassListPage />
      </ProtectedRoute>
      <ProtectedRoute path="/setup">
        <SetupPage />
      </ProtectedRoute>
      <ProtectedRoute path="/accept-invitation/:availabilityId">
        <InvitationAcceptedPage />
      </ProtectedRoute>
      <Route path="/invite">
        <InvitePage />
      </Route>
      <Route path="/enroll-account/:token">
        <SetPasswordPage />
      </Route>
      <Route path="/login">
        <LoginPage loggedInPath="" />
      </Route>
      <ProtectedRoute path="*">
        <Redirect to="/classes" />
      </ProtectedRoute>
    </Switch>
  );
};
