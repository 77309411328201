import * as React from 'react';
import styled from 'styled-components';
import { VideoTrack } from 'twilio-video';

import { ParticipantVideo } from '../common/video/participant-video';

interface Props {
  videoTrack: VideoTrack;
}

export const MaterialsBoard: React.FC<Props> = (props) => {
  return (
    <ScreenVideo
      videoTrack={props.videoTrack}
      videoEnabled={!!props.videoTrack}
    />
  );
};

const ScreenVideo = styled(ParticipantVideo)`
  width: 100%;
  height: 100%;
  background-color: black;
  & > video {
    object-fit: contain;
  }
`;
