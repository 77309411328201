import * as React from 'react';
import { ThemeProvider } from 'styled-components';
import { ApolloProvider } from '@apollo/react-hooks';

import Router from './router';
import { theme } from './common/styled/theme';
import { client } from './common/apollo';
import { AccountsContextProvider } from './common/accounts';
import { MediaSourceProvider } from './common/video/use-selected-media-source';
import { LocalTracksProvider } from './common/video/use-local-tracks';
import { MuteContextProvider } from './common/video/use-mute';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <AccountsContextProvider>
          <MediaSourceProvider>
            <LocalTracksProvider>
              <MuteContextProvider>
                <Router />
              </MuteContextProvider>
            </LocalTracksProvider>
          </MediaSourceProvider>
        </AccountsContextProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default App;
