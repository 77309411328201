import { ErrorMessage, Form, Formik } from 'formik';
import gql from 'graphql-tag';
import * as React from 'react';
import { useHistory, Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useAccounts } from '../common/accounts';
import { useQueryParams } from '../common/hooks/use-query-params';
import { LoginButton } from '../common/styled/buttons/login';
import { LoginContainer } from '../common/styled/containers/login/container';
import { LoginInput } from '../common/styled/input/login/input';
import { Title } from '../common/styled/typography/title';
import { Maybe } from '../common/types';
import {
  useAcceptInvitationByNewUserMutation,
  useOneUnusedReservationQuery,
} from '../generated/graphql';
import { Invitation } from './invitation';
import { Button } from '../common/components/button';

gql`
  mutation AcceptInvitationByNewUser(
    $reservation: ID!
    $email: String!
    $password: String!
    $firstName: String
    $lastName: String
  ) {
    acceptInvitationByNewUser(
      reservation: $reservation
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
    ) {
      id
      availability {
        id
      }
    }
  }
`;

interface Values {
  email: string;
  password: string;
  passwordRepeat: string;
  firstName: string;
  lastName: string;
}

export const NonAuthInvitePage = () => {
  const { login } = useAccounts();
  const queryParams = useQueryParams();
  const history = useHistory();
  const location = useLocation();
  const [email, setEmail] = React.useState<Maybe<string>>();
  const [password, setPassword] = React.useState<Maybe<string>>();

  const {
    data: getInvitationResult,
    loading: getInvitationLoading,
    error: getInvitationError,
  } = useOneUnusedReservationQuery({
    variables: {
      reservationCreator: queryParams.get('user') as string,
      classAvailability: queryParams.get('classAvailability') as string,
    },
  });

  const [
    acceptInvitationByNewUserMutation,
    {
      data: acceptInvitationResult,
      loading: acceptInvitationLoading,
      error: acceptInvitationError,
    },
  ] = useAcceptInvitationByNewUserMutation();

  React.useEffect(() => {
    if (!acceptInvitationResult || !email || !password) return;
    const request = async () => {
      await login(email!, password!);
      history.push(`/accept-invitation/${reservation.availability.id}`);
    };

    request();
  }, [acceptInvitationResult]);

  const [reservation, setReservation] = React.useState<any>();

  React.useEffect(() => {
    if (!getInvitationResult) return;

    setReservation(getInvitationResult?.unusedReservation);
  }, [getInvitationResult]);

  const onSubmit = React.useCallback(
    async (values: Values) => {
      if (!reservation) return;

      setEmail(values.email);
      setPassword(values.password);

      acceptInvitationByNewUserMutation({
        variables: { ...values, reservation: reservation.id },
      });
    },
    [reservation],
  );

  return (
    <div>
      {reservation ? (
        <InvitationDetailsContainer>
          <Invitation reservation={reservation} />
          <Formik
            initialValues={{
              email: '',
              password: '',
              passwordRepeat: '',
              firstName: '',
              lastName: '',
            }}
            onSubmit={onSubmit}
            validate={({ email, password, passwordRepeat }) => {
              return password !== passwordRepeat
                ? { passwordRepeat: 'Passwords have to be equal' }
                : !email
                ? { email: 'Email is mandatory' }
                : {};
            }}
          >
            {() => (
              <LoginContainer>
                <LoginContent>
                  <Title>
                    Please enter an email and password to access Skhool:
                  </Title>
                  <LoginInput name="email" placeholder="Enter Email Address" />
                  <LoginInput name="password" placeholder="Enter Password" />
                  <LoginInput
                    name="passwordRepeat"
                    placeholder="Repeat Password"
                  />
                  <LoginInput name="firstName" placeholder="First Name" />
                  <LoginInput name="lastName" placeholder="Last Name" />
                  <ErrorMessage name="passwordRepeat" />
                  {acceptInvitationError ? (
                    <div>{acceptInvitationError.message}</div>
                  ) : null}
                  {getInvitationError ? (
                    <div>{getInvitationError.message}</div>
                  ) : null}
                  <LoginButton type="submit">Accept Invite</LoginButton>
                  <Link
                    to={{
                      pathname: '/login',
                      state: {
                        from: location.pathname,
                        search: location.search,
                      },
                    }}
                  >
                    Already Have An Account? Login
                  </Link>
                  <Spacer />
                </LoginContent>
              </LoginContainer>
            )}
          </Formik>
        </InvitationDetailsContainer>
      ) : !!getInvitationResult ? (
        <InvitationDetailsContainer>
          <Title>Invitation no longer valid</Title>
          <Button onClick={() => history.push('')}>Back</Button>
        </InvitationDetailsContainer>
      ) : null}
    </div>
  );
};

const Spacer = styled.div`
  flex: 1;
`;

const InvitationDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 20px 0 #00000066;
  padding: 30px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.background};
`;

const LoginContent = styled(Form)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 550px;
  padding: 40px;
`;
