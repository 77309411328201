import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};


export type Query = {
  __typename?: 'Query';
  class?: Maybe<Class>;
  classBySSID?: Maybe<Class>;
  classAvailability?: Maybe<ClassAvailability>;
  classAvailabilityBySSID?: Maybe<ClassAvailability>;
  availableClasses?: Maybe<Array<Class>>;
  unusedReservation?: Maybe<Reservation>;
  usedReservation?: Maybe<Reservation>;
  reservationsForAvailability?: Maybe<Array<Maybe<Reservation>>>;
  twoFactorSecret?: Maybe<TwoFactorSecretKey>;
  getUser?: Maybe<User>;
};


export type QueryClassArgs = {
  id?: Maybe<Scalars['ID']>;
  classAvailabilityId?: Maybe<Scalars['ID']>;
};


export type QueryClassBySsidArgs = {
  squareSpaceId: Scalars['String'];
};


export type QueryClassAvailabilityArgs = {
  id: Scalars['ID'];
};


export type QueryClassAvailabilityBySsidArgs = {
  squareSpaceId: Scalars['String'];
};


export type QueryAvailableClassesArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryUnusedReservationArgs = {
  reservationCreator: Scalars['ID'];
  classAvailability: Scalars['ID'];
};


export type QueryUsedReservationArgs = {
  classAvailability: Scalars['ID'];
  usedBy: Scalars['ID'];
};


export type QueryReservationsForAvailabilityArgs = {
  classAvailability: Scalars['ID'];
  reservingUser?: Maybe<Scalars['ID']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createClass?: Maybe<Class>;
  createClassWithSSID?: Maybe<Class>;
  createAvailability?: Maybe<ClassAvailability>;
  createAvailabilityWithSSID?: Maybe<ClassAvailability>;
  generateRoomToken?: Maybe<Scalars['String']>;
  inviteTeacher?: Maybe<User>;
  assignTeacher?: Maybe<Class>;
  reserveClass?: Maybe<ClassAvailability>;
  reserveClassByUserEmail?: Maybe<Reservation>;
  muteAll?: Maybe<Room>;
  enterRoom?: Maybe<StudentInRoom>;
  forceMuteStudent?: Maybe<StudentInRoom>;
  acceptInvitation?: Maybe<Reservation>;
  acceptInvitationByNewUser?: Maybe<Reservation>;
  createUser?: Maybe<CreateUserResult>;
  verifyEmail?: Maybe<Scalars['Boolean']>;
  resetPassword?: Maybe<LoginResult>;
  sendVerificationEmail?: Maybe<Scalars['Boolean']>;
  sendResetPasswordEmail?: Maybe<Scalars['Boolean']>;
  addEmail?: Maybe<Scalars['Boolean']>;
  changePassword?: Maybe<Scalars['Boolean']>;
  twoFactorSet?: Maybe<Scalars['Boolean']>;
  twoFactorUnset?: Maybe<Scalars['Boolean']>;
  impersonate?: Maybe<ImpersonateReturn>;
  refreshTokens?: Maybe<LoginResult>;
  logout?: Maybe<Scalars['Boolean']>;
  authenticate?: Maybe<LoginResult>;
  verifyAuthentication?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateClassArgs = {
  data: CreateClassInput;
};


export type MutationCreateClassWithSsidArgs = {
  data: CreateClassWithSsidInput;
};


export type MutationCreateAvailabilityArgs = {
  data: CreateClassAvailabilityInput;
};


export type MutationCreateAvailabilityWithSsidArgs = {
  data: CreateClassAvailabilityWithSsidInput;
};


export type MutationGenerateRoomTokenArgs = {
  classAvailabilityId: Scalars['ID'];
};


export type MutationInviteTeacherArgs = {
  data: InviteTeacherInput;
};


export type MutationAssignTeacherArgs = {
  classId: Scalars['ID'];
  teacherId: Scalars['ID'];
};


export type MutationReserveClassArgs = {
  data: ReserveClassInput;
};


export type MutationReserveClassByUserEmailArgs = {
  data: ReserveClassByUserEmailInput;
};


export type MutationMuteAllArgs = {
  roomId: Scalars['ID'];
  mute: Scalars['Boolean'];
};


export type MutationEnterRoomArgs = {
  roomId: Scalars['ID'];
};


export type MutationForceMuteStudentArgs = {
  roomId: Scalars['ID'];
  studentId: Scalars['ID'];
  mute: Scalars['Boolean'];
};


export type MutationAcceptInvitationArgs = {
  reservation: Scalars['ID'];
  byUser: Scalars['ID'];
};


export type MutationAcceptInvitationByNewUserArgs = {
  reservation: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};


export type MutationCreateUserArgs = {
  user: CreateUserInput;
};


export type MutationVerifyEmailArgs = {
  token: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  token: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationSendVerificationEmailArgs = {
  email: Scalars['String'];
};


export type MutationSendResetPasswordEmailArgs = {
  email: Scalars['String'];
};


export type MutationAddEmailArgs = {
  newEmail: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationTwoFactorSetArgs = {
  secret: TwoFactorSecretKeyInput;
  code: Scalars['String'];
};


export type MutationTwoFactorUnsetArgs = {
  code: Scalars['String'];
};


export type MutationImpersonateArgs = {
  accessToken: Scalars['String'];
  impersonated: ImpersonationUserIdentityInput;
};


export type MutationRefreshTokensArgs = {
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};


export type MutationAuthenticateArgs = {
  serviceName: Scalars['String'];
  params: AuthenticateParamsInput;
};


export type MutationVerifyAuthenticationArgs = {
  serviceName: Scalars['String'];
  params: AuthenticateParamsInput;
};

export type CreateClassInput = {
  name: Scalars['String'];
  maxNoOfStudents: Scalars['Int'];
  durationHours: Scalars['Float'];
};

export type CreateClassWithSsidInput = {
  squareSpaceId: Scalars['String'];
  name: Scalars['String'];
  maxNoOfStudents: Scalars['Int'];
  durationHours: Scalars['Float'];
};

export type InviteTeacherInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type Class = {
  __typename?: 'Class';
  id: Scalars['ID'];
  squareSpaceId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  teacher?: Maybe<User>;
  availabilities?: Maybe<Array<ClassAvailability>>;
  slots: Scalars['Int'];
  durationHours: Scalars['Float'];
};


export type ClassAvailabilitiesArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type ClassAvailability = {
  __typename?: 'ClassAvailability';
  id: Scalars['ID'];
  squareSpaceId?: Maybe<Scalars['String']>;
  class: Class;
  mainRoom?: Maybe<Room>;
  reservations?: Maybe<Array<Reservation>>;
  startDateTime: Scalars['Date'];
  endDateTime: Scalars['Date'];
  myReservation?: Maybe<Reservation>;
};

export type CreateClassAvailabilityInput = {
  classId: Scalars['ID'];
  startDateTime: Scalars['Date'];
  endDateTime: Scalars['Date'];
};

export type CreateClassAvailabilityWithSsidInput = {
  squareSpaceId: Scalars['String'];
  classId: Scalars['ID'];
  startDateTime: Scalars['Date'];
  endDateTime: Scalars['Date'];
};

export type ReserveClassInput = {
  classAvailabilityId: Scalars['ID'];
  reservingUser: Scalars['ID'];
  slots: Scalars['Int'];
};

export type ReserveClassByUserEmailInput = {
  squareSpaceId: Scalars['String'];
  classAvailabilityId: Scalars['ID'];
  reservingUserEmail: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  slots: Scalars['Int'];
};

export type Reservation = {
  __typename?: 'Reservation';
  id: Scalars['ID'];
  squareSpaceId?: Maybe<Scalars['String']>;
  reservingUser: User;
  slots: Scalars['Int'];
  availability: ClassAvailability;
  usedBy?: Maybe<User>;
};

export type Room = {
  __typename?: 'Room';
  id: Scalars['ID'];
  muteAll?: Maybe<Scalars['Boolean']>;
  studentStatus?: Maybe<Array<StudentInRoom>>;
};

export type StudentInRoom = {
  __typename?: 'StudentInRoom';
  id: Scalars['ID'];
  student: User;
  room: Room;
  online?: Maybe<Scalars['Boolean']>;
  forceMute?: Maybe<Scalars['Boolean']>;
};

export type User = {
  __typename?: 'User';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  id: Scalars['ID'];
  emails?: Maybe<Array<EmailRecord>>;
  username?: Maybe<Scalars['String']>;
};

export enum Role {
  Principal = 'Principal',
  Teacher = 'Teacher',
  Student = 'Student'
}

export type EmailRecord = {
  __typename?: 'EmailRecord';
  address?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
};

export type Tokens = {
  __typename?: 'Tokens';
  refreshToken?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
};

export type LoginResult = {
  __typename?: 'LoginResult';
  sessionId?: Maybe<Scalars['String']>;
  tokens?: Maybe<Tokens>;
  user?: Maybe<User>;
};

export type CreateUserResult = {
  __typename?: 'CreateUserResult';
  userId?: Maybe<Scalars['ID']>;
  loginResult?: Maybe<LoginResult>;
};

export type TwoFactorSecretKey = {
  __typename?: 'TwoFactorSecretKey';
  ascii?: Maybe<Scalars['String']>;
  base32?: Maybe<Scalars['String']>;
  hex?: Maybe<Scalars['String']>;
  qr_code_ascii?: Maybe<Scalars['String']>;
  qr_code_hex?: Maybe<Scalars['String']>;
  qr_code_base32?: Maybe<Scalars['String']>;
  google_auth_qr?: Maybe<Scalars['String']>;
  otpauth_url?: Maybe<Scalars['String']>;
};

export type TwoFactorSecretKeyInput = {
  ascii?: Maybe<Scalars['String']>;
  base32?: Maybe<Scalars['String']>;
  hex?: Maybe<Scalars['String']>;
  qr_code_ascii?: Maybe<Scalars['String']>;
  qr_code_hex?: Maybe<Scalars['String']>;
  qr_code_base32?: Maybe<Scalars['String']>;
  google_auth_qr?: Maybe<Scalars['String']>;
  otpauth_url?: Maybe<Scalars['String']>;
};

export type CreateUserInput = {
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

export type ImpersonateReturn = {
  __typename?: 'ImpersonateReturn';
  authorized?: Maybe<Scalars['Boolean']>;
  tokens?: Maybe<Tokens>;
  user?: Maybe<User>;
};

export type UserInput = {
  id?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type AuthenticateParamsInput = {
  access_token?: Maybe<Scalars['String']>;
  access_token_secret?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  user?: Maybe<UserInput>;
  code?: Maybe<Scalars['String']>;
};

export type ImpersonationUserIdentityInput = {
  userId?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type AdditionalEntityFields = {
  path?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}


export type GetAvailableClassesQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetAvailableClassesQuery = (
  { __typename?: 'Query' }
  & { availableClasses?: Maybe<Array<(
    { __typename?: 'Class' }
    & Pick<Class, 'id' | 'name' | 'slots' | 'durationHours'>
    & { teacher?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    )>, availabilities?: Maybe<Array<(
      { __typename?: 'ClassAvailability' }
      & Pick<ClassAvailability, 'id' | 'startDateTime' | 'endDateTime'>
      & { mainRoom?: Maybe<(
        { __typename?: 'Room' }
        & Pick<Room, 'id'>
      )> }
    )>> }
  )>> }
);

export type GetClassAvailabilityWithMyReservationQueryVariables = Exact<{
  availabilityId: Scalars['ID'];
}>;


export type GetClassAvailabilityWithMyReservationQuery = (
  { __typename?: 'Query' }
  & { classAvailability?: Maybe<(
    { __typename?: 'ClassAvailability' }
    & Pick<ClassAvailability, 'id' | 'startDateTime' | 'endDateTime'>
    & { class: (
      { __typename?: 'Class' }
      & Pick<Class, 'id' | 'name'>
      & { teacher?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
      )> }
    ), mainRoom?: Maybe<(
      { __typename?: 'Room' }
      & Pick<Room, 'id'>
    )>, myReservation?: Maybe<(
      { __typename?: 'Reservation' }
      & Pick<Reservation, 'id'>
    )> }
  )> }
);

export type GetClassAvailabilityQueryVariables = Exact<{
  availabilityId: Scalars['ID'];
}>;


export type GetClassAvailabilityQuery = (
  { __typename?: 'Query' }
  & { classAvailability?: Maybe<(
    { __typename?: 'ClassAvailability' }
    & Pick<ClassAvailability, 'id' | 'startDateTime' | 'endDateTime'>
    & { class: (
      { __typename?: 'Class' }
      & Pick<Class, 'id' | 'name'>
      & { teacher?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
      )> }
    ), mainRoom?: Maybe<(
      { __typename?: 'Room' }
      & Pick<Room, 'id'>
    )> }
  )> }
);

export type UserFieldsFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'firstName' | 'lastName' | 'role'>
  & { emails?: Maybe<Array<(
    { __typename?: 'EmailRecord' }
    & Pick<EmailRecord, 'address'>
  )>> }
);

export type OneUnusedReservationQueryVariables = Exact<{
  reservationCreator: Scalars['ID'];
  classAvailability: Scalars['ID'];
}>;


export type OneUnusedReservationQuery = (
  { __typename?: 'Query' }
  & { unusedReservation?: Maybe<(
    { __typename?: 'Reservation' }
    & Pick<Reservation, 'id'>
    & { reservingUser: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName'>
      & { emails?: Maybe<Array<(
        { __typename?: 'EmailRecord' }
        & Pick<EmailRecord, 'address'>
      )>> }
    ), availability: (
      { __typename?: 'ClassAvailability' }
      & Pick<ClassAvailability, 'id' | 'startDateTime' | 'endDateTime'>
      & { class: (
        { __typename?: 'Class' }
        & Pick<Class, 'name'>
        & { teacher?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'firstName' | 'lastName'>
          & { emails?: Maybe<Array<(
            { __typename?: 'EmailRecord' }
            & Pick<EmailRecord, 'address'>
          )>> }
        )> }
      ) }
    ) }
  )> }
);

export type GenerateAccessTokenMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GenerateAccessTokenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'generateRoomToken'>
);

export type AcceptInvitationMutationVariables = Exact<{
  reservation: Scalars['ID'];
  byUser: Scalars['ID'];
}>;


export type AcceptInvitationMutation = (
  { __typename?: 'Mutation' }
  & { acceptInvitation?: Maybe<(
    { __typename?: 'Reservation' }
    & Pick<Reservation, 'id'>
    & { availability: (
      { __typename?: 'ClassAvailability' }
      & Pick<ClassAvailability, 'id'>
    ) }
  )> }
);

export type GetUsedReservationQueryVariables = Exact<{
  classAvailability: Scalars['ID'];
  usedBy: Scalars['ID'];
}>;


export type GetUsedReservationQuery = (
  { __typename?: 'Query' }
  & { usedReservation?: Maybe<(
    { __typename?: 'Reservation' }
    & Pick<Reservation, 'id'>
  )> }
);

export type AcceptInvitationByNewUserMutationVariables = Exact<{
  reservation: Scalars['ID'];
  email: Scalars['String'];
  password: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
}>;


export type AcceptInvitationByNewUserMutation = (
  { __typename?: 'Mutation' }
  & { acceptInvitationByNewUser?: Maybe<(
    { __typename?: 'Reservation' }
    & Pick<Reservation, 'id'>
    & { availability: (
      { __typename?: 'ClassAvailability' }
      & Pick<ClassAvailability, 'id'>
    ) }
  )> }
);

export const UserFieldsFragmentDoc = gql`
    fragment userFields on User {
  id
  emails {
    address
  }
  firstName
  lastName
  role
}
    `;
export const GetAvailableClassesDocument = gql`
    query GetAvailableClasses($skip: Int, $limit: Int) {
  availableClasses(skip: $skip, limit: $limit) {
    id
    name
    teacher {
      id
      firstName
      lastName
    }
    availabilities {
      id
      mainRoom {
        id
      }
      startDateTime
      endDateTime
    }
    slots
    durationHours
  }
}
    `;
export type GetAvailableClassesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAvailableClassesQuery, GetAvailableClassesQueryVariables>, 'query'>;

    export const GetAvailableClassesComponent = (props: GetAvailableClassesComponentProps) => (
      <ApolloReactComponents.Query<GetAvailableClassesQuery, GetAvailableClassesQueryVariables> query={GetAvailableClassesDocument} {...props} />
    );
    

/**
 * __useGetAvailableClassesQuery__
 *
 * To run a query within a React component, call `useGetAvailableClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableClassesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAvailableClassesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAvailableClassesQuery, GetAvailableClassesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAvailableClassesQuery, GetAvailableClassesQueryVariables>(GetAvailableClassesDocument, baseOptions);
      }
export function useGetAvailableClassesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAvailableClassesQuery, GetAvailableClassesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAvailableClassesQuery, GetAvailableClassesQueryVariables>(GetAvailableClassesDocument, baseOptions);
        }
export type GetAvailableClassesQueryHookResult = ReturnType<typeof useGetAvailableClassesQuery>;
export type GetAvailableClassesLazyQueryHookResult = ReturnType<typeof useGetAvailableClassesLazyQuery>;
export type GetAvailableClassesQueryResult = ApolloReactCommon.QueryResult<GetAvailableClassesQuery, GetAvailableClassesQueryVariables>;
export const GetClassAvailabilityWithMyReservationDocument = gql`
    query GetClassAvailabilityWithMyReservation($availabilityId: ID!) {
  classAvailability(id: $availabilityId) {
    id
    startDateTime
    endDateTime
    class {
      id
      name
      teacher {
        id
        firstName
        lastName
      }
    }
    mainRoom {
      id
    }
    myReservation {
      id
    }
  }
}
    `;
export type GetClassAvailabilityWithMyReservationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetClassAvailabilityWithMyReservationQuery, GetClassAvailabilityWithMyReservationQueryVariables>, 'query'> & ({ variables: GetClassAvailabilityWithMyReservationQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetClassAvailabilityWithMyReservationComponent = (props: GetClassAvailabilityWithMyReservationComponentProps) => (
      <ApolloReactComponents.Query<GetClassAvailabilityWithMyReservationQuery, GetClassAvailabilityWithMyReservationQueryVariables> query={GetClassAvailabilityWithMyReservationDocument} {...props} />
    );
    

/**
 * __useGetClassAvailabilityWithMyReservationQuery__
 *
 * To run a query within a React component, call `useGetClassAvailabilityWithMyReservationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassAvailabilityWithMyReservationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassAvailabilityWithMyReservationQuery({
 *   variables: {
 *      availabilityId: // value for 'availabilityId'
 *   },
 * });
 */
export function useGetClassAvailabilityWithMyReservationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClassAvailabilityWithMyReservationQuery, GetClassAvailabilityWithMyReservationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClassAvailabilityWithMyReservationQuery, GetClassAvailabilityWithMyReservationQueryVariables>(GetClassAvailabilityWithMyReservationDocument, baseOptions);
      }
export function useGetClassAvailabilityWithMyReservationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClassAvailabilityWithMyReservationQuery, GetClassAvailabilityWithMyReservationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClassAvailabilityWithMyReservationQuery, GetClassAvailabilityWithMyReservationQueryVariables>(GetClassAvailabilityWithMyReservationDocument, baseOptions);
        }
export type GetClassAvailabilityWithMyReservationQueryHookResult = ReturnType<typeof useGetClassAvailabilityWithMyReservationQuery>;
export type GetClassAvailabilityWithMyReservationLazyQueryHookResult = ReturnType<typeof useGetClassAvailabilityWithMyReservationLazyQuery>;
export type GetClassAvailabilityWithMyReservationQueryResult = ApolloReactCommon.QueryResult<GetClassAvailabilityWithMyReservationQuery, GetClassAvailabilityWithMyReservationQueryVariables>;
export const GetClassAvailabilityDocument = gql`
    query GetClassAvailability($availabilityId: ID!) {
  classAvailability(id: $availabilityId) {
    id
    startDateTime
    endDateTime
    class {
      id
      name
      teacher {
        id
        firstName
        lastName
      }
    }
    mainRoom {
      id
    }
  }
}
    `;
export type GetClassAvailabilityComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetClassAvailabilityQuery, GetClassAvailabilityQueryVariables>, 'query'> & ({ variables: GetClassAvailabilityQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetClassAvailabilityComponent = (props: GetClassAvailabilityComponentProps) => (
      <ApolloReactComponents.Query<GetClassAvailabilityQuery, GetClassAvailabilityQueryVariables> query={GetClassAvailabilityDocument} {...props} />
    );
    

/**
 * __useGetClassAvailabilityQuery__
 *
 * To run a query within a React component, call `useGetClassAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassAvailabilityQuery({
 *   variables: {
 *      availabilityId: // value for 'availabilityId'
 *   },
 * });
 */
export function useGetClassAvailabilityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClassAvailabilityQuery, GetClassAvailabilityQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClassAvailabilityQuery, GetClassAvailabilityQueryVariables>(GetClassAvailabilityDocument, baseOptions);
      }
export function useGetClassAvailabilityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClassAvailabilityQuery, GetClassAvailabilityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClassAvailabilityQuery, GetClassAvailabilityQueryVariables>(GetClassAvailabilityDocument, baseOptions);
        }
export type GetClassAvailabilityQueryHookResult = ReturnType<typeof useGetClassAvailabilityQuery>;
export type GetClassAvailabilityLazyQueryHookResult = ReturnType<typeof useGetClassAvailabilityLazyQuery>;
export type GetClassAvailabilityQueryResult = ApolloReactCommon.QueryResult<GetClassAvailabilityQuery, GetClassAvailabilityQueryVariables>;
export const OneUnusedReservationDocument = gql`
    query OneUnusedReservation($reservationCreator: ID!, $classAvailability: ID!) {
  unusedReservation(reservationCreator: $reservationCreator, classAvailability: $classAvailability) {
    id
    reservingUser {
      firstName
      lastName
      emails {
        address
      }
    }
    availability {
      id
      class {
        name
        teacher {
          firstName
          lastName
          emails {
            address
          }
        }
      }
      startDateTime
      endDateTime
    }
  }
}
    `;
export type OneUnusedReservationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OneUnusedReservationQuery, OneUnusedReservationQueryVariables>, 'query'> & ({ variables: OneUnusedReservationQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OneUnusedReservationComponent = (props: OneUnusedReservationComponentProps) => (
      <ApolloReactComponents.Query<OneUnusedReservationQuery, OneUnusedReservationQueryVariables> query={OneUnusedReservationDocument} {...props} />
    );
    

/**
 * __useOneUnusedReservationQuery__
 *
 * To run a query within a React component, call `useOneUnusedReservationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOneUnusedReservationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOneUnusedReservationQuery({
 *   variables: {
 *      reservationCreator: // value for 'reservationCreator'
 *      classAvailability: // value for 'classAvailability'
 *   },
 * });
 */
export function useOneUnusedReservationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OneUnusedReservationQuery, OneUnusedReservationQueryVariables>) {
        return ApolloReactHooks.useQuery<OneUnusedReservationQuery, OneUnusedReservationQueryVariables>(OneUnusedReservationDocument, baseOptions);
      }
export function useOneUnusedReservationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OneUnusedReservationQuery, OneUnusedReservationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OneUnusedReservationQuery, OneUnusedReservationQueryVariables>(OneUnusedReservationDocument, baseOptions);
        }
export type OneUnusedReservationQueryHookResult = ReturnType<typeof useOneUnusedReservationQuery>;
export type OneUnusedReservationLazyQueryHookResult = ReturnType<typeof useOneUnusedReservationLazyQuery>;
export type OneUnusedReservationQueryResult = ApolloReactCommon.QueryResult<OneUnusedReservationQuery, OneUnusedReservationQueryVariables>;
export const GenerateAccessTokenDocument = gql`
    mutation GenerateAccessToken($id: ID!) {
  generateRoomToken(classAvailabilityId: $id)
}
    `;
export type GenerateAccessTokenMutationFn = ApolloReactCommon.MutationFunction<GenerateAccessTokenMutation, GenerateAccessTokenMutationVariables>;
export type GenerateAccessTokenComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GenerateAccessTokenMutation, GenerateAccessTokenMutationVariables>, 'mutation'>;

    export const GenerateAccessTokenComponent = (props: GenerateAccessTokenComponentProps) => (
      <ApolloReactComponents.Mutation<GenerateAccessTokenMutation, GenerateAccessTokenMutationVariables> mutation={GenerateAccessTokenDocument} {...props} />
    );
    

/**
 * __useGenerateAccessTokenMutation__
 *
 * To run a mutation, you first call `useGenerateAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateAccessTokenMutation, { data, loading, error }] = useGenerateAccessTokenMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGenerateAccessTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GenerateAccessTokenMutation, GenerateAccessTokenMutationVariables>) {
        return ApolloReactHooks.useMutation<GenerateAccessTokenMutation, GenerateAccessTokenMutationVariables>(GenerateAccessTokenDocument, baseOptions);
      }
export type GenerateAccessTokenMutationHookResult = ReturnType<typeof useGenerateAccessTokenMutation>;
export type GenerateAccessTokenMutationResult = ApolloReactCommon.MutationResult<GenerateAccessTokenMutation>;
export type GenerateAccessTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<GenerateAccessTokenMutation, GenerateAccessTokenMutationVariables>;
export const AcceptInvitationDocument = gql`
    mutation AcceptInvitation($reservation: ID!, $byUser: ID!) {
  acceptInvitation(reservation: $reservation, byUser: $byUser) {
    id
    availability {
      id
    }
  }
}
    `;
export type AcceptInvitationMutationFn = ApolloReactCommon.MutationFunction<AcceptInvitationMutation, AcceptInvitationMutationVariables>;
export type AcceptInvitationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AcceptInvitationMutation, AcceptInvitationMutationVariables>, 'mutation'>;

    export const AcceptInvitationComponent = (props: AcceptInvitationComponentProps) => (
      <ApolloReactComponents.Mutation<AcceptInvitationMutation, AcceptInvitationMutationVariables> mutation={AcceptInvitationDocument} {...props} />
    );
    

/**
 * __useAcceptInvitationMutation__
 *
 * To run a mutation, you first call `useAcceptInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInvitationMutation, { data, loading, error }] = useAcceptInvitationMutation({
 *   variables: {
 *      reservation: // value for 'reservation'
 *      byUser: // value for 'byUser'
 *   },
 * });
 */
export function useAcceptInvitationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AcceptInvitationMutation, AcceptInvitationMutationVariables>) {
        return ApolloReactHooks.useMutation<AcceptInvitationMutation, AcceptInvitationMutationVariables>(AcceptInvitationDocument, baseOptions);
      }
export type AcceptInvitationMutationHookResult = ReturnType<typeof useAcceptInvitationMutation>;
export type AcceptInvitationMutationResult = ApolloReactCommon.MutationResult<AcceptInvitationMutation>;
export type AcceptInvitationMutationOptions = ApolloReactCommon.BaseMutationOptions<AcceptInvitationMutation, AcceptInvitationMutationVariables>;
export const GetUsedReservationDocument = gql`
    query GetUsedReservation($classAvailability: ID!, $usedBy: ID!) {
  usedReservation(classAvailability: $classAvailability, usedBy: $usedBy) {
    id
  }
}
    `;
export type GetUsedReservationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUsedReservationQuery, GetUsedReservationQueryVariables>, 'query'> & ({ variables: GetUsedReservationQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetUsedReservationComponent = (props: GetUsedReservationComponentProps) => (
      <ApolloReactComponents.Query<GetUsedReservationQuery, GetUsedReservationQueryVariables> query={GetUsedReservationDocument} {...props} />
    );
    

/**
 * __useGetUsedReservationQuery__
 *
 * To run a query within a React component, call `useGetUsedReservationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsedReservationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsedReservationQuery({
 *   variables: {
 *      classAvailability: // value for 'classAvailability'
 *      usedBy: // value for 'usedBy'
 *   },
 * });
 */
export function useGetUsedReservationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUsedReservationQuery, GetUsedReservationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUsedReservationQuery, GetUsedReservationQueryVariables>(GetUsedReservationDocument, baseOptions);
      }
export function useGetUsedReservationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUsedReservationQuery, GetUsedReservationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUsedReservationQuery, GetUsedReservationQueryVariables>(GetUsedReservationDocument, baseOptions);
        }
export type GetUsedReservationQueryHookResult = ReturnType<typeof useGetUsedReservationQuery>;
export type GetUsedReservationLazyQueryHookResult = ReturnType<typeof useGetUsedReservationLazyQuery>;
export type GetUsedReservationQueryResult = ApolloReactCommon.QueryResult<GetUsedReservationQuery, GetUsedReservationQueryVariables>;
export const AcceptInvitationByNewUserDocument = gql`
    mutation AcceptInvitationByNewUser($reservation: ID!, $email: String!, $password: String!, $firstName: String, $lastName: String) {
  acceptInvitationByNewUser(reservation: $reservation, email: $email, password: $password, firstName: $firstName, lastName: $lastName) {
    id
    availability {
      id
    }
  }
}
    `;
export type AcceptInvitationByNewUserMutationFn = ApolloReactCommon.MutationFunction<AcceptInvitationByNewUserMutation, AcceptInvitationByNewUserMutationVariables>;
export type AcceptInvitationByNewUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AcceptInvitationByNewUserMutation, AcceptInvitationByNewUserMutationVariables>, 'mutation'>;

    export const AcceptInvitationByNewUserComponent = (props: AcceptInvitationByNewUserComponentProps) => (
      <ApolloReactComponents.Mutation<AcceptInvitationByNewUserMutation, AcceptInvitationByNewUserMutationVariables> mutation={AcceptInvitationByNewUserDocument} {...props} />
    );
    

/**
 * __useAcceptInvitationByNewUserMutation__
 *
 * To run a mutation, you first call `useAcceptInvitationByNewUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInvitationByNewUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInvitationByNewUserMutation, { data, loading, error }] = useAcceptInvitationByNewUserMutation({
 *   variables: {
 *      reservation: // value for 'reservation'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useAcceptInvitationByNewUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AcceptInvitationByNewUserMutation, AcceptInvitationByNewUserMutationVariables>) {
        return ApolloReactHooks.useMutation<AcceptInvitationByNewUserMutation, AcceptInvitationByNewUserMutationVariables>(AcceptInvitationByNewUserDocument, baseOptions);
      }
export type AcceptInvitationByNewUserMutationHookResult = ReturnType<typeof useAcceptInvitationByNewUserMutation>;
export type AcceptInvitationByNewUserMutationResult = ApolloReactCommon.MutationResult<AcceptInvitationByNewUserMutation>;
export type AcceptInvitationByNewUserMutationOptions = ApolloReactCommon.BaseMutationOptions<AcceptInvitationByNewUserMutation, AcceptInvitationByNewUserMutationVariables>;