import React from 'react';
import {
  Room,
  RemoteParticipant,
  connect,
  LocalTrackPublication,
  LocalAudioTrackPublication,
  LocalVideoTrack,
  ConnectOptions,
  LocalAudioTrack,
} from 'twilio-video';
import gql from 'graphql-tag';

import { useGenerateAccessTokenMutation } from '../../generated/graphql';
import { useSelectedMediaSource } from './use-selected-media-source';

gql`
  mutation GenerateAccessToken($id: ID!) {
    generateRoomToken(classAvailabilityId: $id)
  }
`;

type ExpectedRoomFragment = { id: string; name: string } | null;
export const useRoom = (
  roomFragment?: ExpectedRoomFragment,
  priority?: 'high' | 'standard' | 'low',
  localVideoTrack?: LocalVideoTrack | null,
  localAudioTrack?: LocalAudioTrack | null,
) => {
  const [room, setRoom] = React.useState<Room | null>(null);
  const [participants, setParticipants] = React.useState<Map<
    string,
    RemoteParticipant
  > | null>(null);
  const { videoSource, audioInputSource } = useSelectedMediaSource();
  const roomId = roomFragment?.id;
  const roomName = roomFragment?.name;
  const [generateAccessToken] = useGenerateAccessTokenMutation();

  React.useEffect(() => {
    if (roomId) {
      console.log('Connecting to room', roomId, roomName);
      let localRoom: Room | null = null;
      generateAccessToken({ variables: { id: roomId } })
        .then(({ data }) => data?.generateRoomToken)
        .then((accessToken) => {
          if (!accessToken) throw new Error('Not authorized to enter the room');
          const config = {} as ConnectOptions;
          const tracks = [];
          if (localVideoTrack) tracks.push(localVideoTrack);
          else config.video = { deviceId: videoSource };

          if (localAudioTrack) tracks.push(localAudioTrack);
          else config.audio = { deviceId: audioInputSource };

          if (tracks.length) config.tracks = tracks;
          return connect(accessToken, {
            ...config,
            name: roomId,
            preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
          });
        })
        .then((room) => {
          setRoom(room);
          localRoom = room;
        })
        .catch((e) => {
          // alert(e);
          console.error(e);
        });
      return () => {
        console.log('Disconnect on connect side effect', localRoom?.name);
        localRoom?.disconnect();
      };
    }
  }, [generateAccessToken, roomId, roomName, videoSource, audioInputSource]);

  React.useEffect(() => {
    if (room) {
      console.log('Attaching participants', room.name);
      if (priority) {
        room.localParticipant.tracks.forEach(
          (publication: LocalTrackPublication) =>
            (publication as any).setPriority(priority),
        );
      }
      setParticipants(room.participants);
      room.on('participantConnected', (participant: RemoteParticipant) => {
        console.log('Participant connected', participant.identity);
        setParticipants(
          new Map(room.participants).set(participant.sid, participant),
        );
      });
      room.on('participantDisconnected', (participant: RemoteParticipant) => {
        console.log('Participant Disconnected', participant.identity);
        const updatedParticipants = new Map(room.participants);
        updatedParticipants.delete(participant.sid);
        setParticipants(updatedParticipants);
      });
      room.on('disconnected', (room: Room) => {
        console.log('Disconnected from room', room.name);
        room.localParticipant.tracks.forEach((publication) => {
          if (publication.track.kind !== 'data') {
            const attachedElements = publication.track.detach();
            attachedElements.forEach((element) => element.remove());
          }
        });
      });

      const unloadCallback = () => {
        console.log('Unload window. Disconnecting');
        room.disconnect();
      };
      window.addEventListener('beforeunload', unloadCallback);

      return () => {
        console.log('Disconnecting', room.name);
        room.disconnect();
        window.removeEventListener('beforeunload', unloadCallback);
      };
    }
  }, [room, priority]);

  const participantsArray = React.useMemo(
    () => Array.from(participants?.values() ?? []),
    [participants],
  );
  return { room, participants: participantsArray };
};
