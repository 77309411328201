import * as React from 'react';
import { useHistory, Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '../common/components/button';
import { Title } from '../common/styled/typography/title';
import moment from 'moment';
import { Subtitle } from '../common/styled/typography/subtitle';

interface Props {}

export const InAccessibleClass: React.FC<Props> = () => {
  const history = useHistory();

  return (
    <InvitationDetailsContainer>
      <Title>Class is only available for registered students</Title>
      <a href="https://theschoolco.squarespace.com/classes" target="_blank">
        You can register to classes here:
      </a>
      <Button onClick={() => history.push('')}>Back</Button>
    </InvitationDetailsContainer>
  );
};

const InvitationDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 20px 0 #00000066;
  padding: 30px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.background};
`;
