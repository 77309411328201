import * as React from 'react';
import { AccountsClient } from '@accounts/client';
import { AccountsClientPassword } from '@accounts/client-password';
import { AccountsGraphQLClient } from '@accounts/graphql-client';
import gql from 'graphql-tag';

import { client as apolloClient } from './apollo';
import { User } from '../generated/graphql';

const userFieldsFragment = gql`
  fragment userFields on User {
    id
    emails {
      address
    }
    firstName
    lastName
    role
  }
`;

const accountsGraphQL = new AccountsGraphQLClient({
  graphQLClient: apolloClient,
  userFieldsFragment,
});

export const accountsClient = new AccountsClient({}, accountsGraphQL);

// Create service client
export const passwordClient = new AccountsClientPassword(accountsClient);

interface ContextState {
  user: null | User;
  isLoggedIn: boolean;
  loggingIn: boolean;
  login: (email: string, password: string) => void;
  logout: () => void;
  resetPassword: (
    token: string,
    password: string,
    onReset: VoidFunction,
    onError?: (e: Error) => void,
  ) => void;
}

const AccountsContext = React.createContext<ContextState>({
  user: null,
  isLoggedIn: false,
  loggingIn: true,
  login: () => null,
  logout: () => null,
  resetPassword: () => null,
});

export const AccountsContextProvider: React.FC = (props) => {
  const [user, setUser] = React.useState<null | User>(null);
  const [loggingIn, setLoggingIn] = React.useState(true);

  const login = React.useCallback(
    async (email: string, password: string) => {
      const res = await passwordClient.login({ user: { email }, password });
      setUser(res.user);
      return;
    },
    [setUser, setLoggingIn],
  );

  const logout = React.useCallback(() => {
    accountsClient.logout();
    setUser(null);
  }, [setUser]);

  const resetPassword = React.useCallback(
    (
      token: string,
      password: string,
      onReset: VoidFunction,
      onError?: (e: Error) => void,
    ) =>
      passwordClient
        .resetPassword(token, password)
        .then((res) => {
          onReset();
        })
        .catch((e) => {
          onError && onError(e);
        }),
    [],
  );

  React.useEffect(() => {
    accountsClient
      .getUser()
      .then((res) => {
        setUser(res);
        setLoggingIn(false);
      })
      .catch((e) => {
        console.log(e);
        setLoggingIn(false);
      });
  }, []);

  return (
    <AccountsContext.Provider
      value={{
        user,
        isLoggedIn: !!user,
        loggingIn,
        login,
        logout,
        resetPassword,
      }}
    >
      {props.children}
    </AccountsContext.Provider>
  );
};

export const useAccounts = () => React.useContext(AccountsContext);
